<template>
  <div
    class="container d-flex justify-content-center align-items-center vh-100"
  >
    <div class="circle top-left"></div>

    <div class="login-form card p-4">
      <h1 class="text-center mb-4"><img src="img/logo1.png" /></h1>
      <h1 class="text-center mb-4 form-title">Welcome Back</h1>
      <p class="text-center mb-4 form-description">
        Lets login to your account for making building smarter
      </p>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <!-- <label for="username">Username</label> -->
          <input
            type="text"
            class="form-control form-input"
            placeholder="User Name"
            v-model.trim="username"
            :class="{ 'is-invalid': isInvalidUsername }"
          />
          <div class="invalid-feedback" v-if="isInvalidUsername">
            Username is required
          </div>
        </div>
        <div class="form-group">
          <!-- <label for="password">Password</label> -->
          <input
            type="password"
            class="form-control form-input"
            placeholder="Password"
            v-model="password"
            :class="{ 'is-invalid': isInvalidPassword }"
          />
          <div class="invalid-feedback" v-if="isInvalidPassword">
            Password is required
          </div>
        </div>

        <!-- <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="defaultCheck1"
          />
          <label class="form-check-label form-description" for="defaultCheck1">
            I agree with <b>terms & condition</b> of Lotti
          </label>
        </div> -->

        <!-- <div class="form-group">
          <p class="text-center mb-4 form-description"  sytle="display:flex">
            <span>
              <input class="form-check-input" type="checkbox" required>
            </span>
            I agree with <b>terms & condition</b> of Lotti
          </p>
        </div> -->
        <button type="submit" class="btn btn-block login-btn">Login</button>
      </form>
    </div>
    <div class="semi-circle bottom-right"></div>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
import config from "../../config";
export default {
  data() {
    return {
      username: "",
      password: "",
      isInvalidUsername: false,
      isInvalidPassword: false,
      loading: false,
    };
  },
  components: {
    Loader,
  },
  computed: {},
  methods: {
    async submitForm() {
      if (this.username == "" || this.password == "") {
        this.isInvalidPassword = true;
        this.isInvalidUsername = true;
        return;
      }

      try {
        this.loading = true;
        const response = await axios.post(
          config.baseURL + "auth/login",
          {
            username: this.username,
            password: this.password,
          }
        );
        const { token, name, type, project_id } = response.data.data;
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", token.original.access_token);
        localStorage.setItem("token_expires_in", token.original.expires_in);
        localStorage.setItem("token_refresh", token.original.refresh_token);
        localStorage.setItem("token_type", token.original.token_type);
        localStorage.setItem("name", name);
        localStorage.setItem("project_id", project_id);
        localStorage.setItem("type", type);

        this.loading = false;
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "You have logged in Sucessfully",
          showConfirmButton: false,
          timer: 1500,
        });

        if (type === "Admin") {
          this.$router.push("/dashboard");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops.....",
            text: "Sorry! you don't have permission to access this dashboard",
          });
        }
      } catch (error) {
        this.loading = false;
        error?.response?.data
          ? Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            })
          : Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Internal server error, Try again later!",
            });
      }
    },
  },
};
</script>

<style>
/* Center the login container */
.form-title {
  color: black !important;
  font-family: Poppins;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.form-input {
  color: black !important;
}
.form-description {
  color: black !important;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.1%;
}
.container {
  min-height: 100vh;
}
.login-btn {
  background: #10ad9b;
  height: 54px;
  border-radius: 4px;
  padding: 16px;
  color: #ffff;
}
.login-btn:hover {
  background: #10ad9b !important;
  height: 54px;
  border-radius: 4px;
  padding: 16px;
  color: #ffff;
}

.login-btn:focus {
  background: #10ad9b !important;
  color: #ffff !important;
}
.login-btn:active:focus {
  background: #10ad9b !important;
  color: #ffff !important;
}
body {
  background-color: #fafcfe !important;
}

/* Style the login form */
.login-form {
  width: 558px;
  border-radius: 8px;
  background-color: #fff !important;
}

/* Add box shadow */
.shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

/* Center the login form content */
.login-form h1 {
  text-align: center;
  margin-bottom: 10px;
}

.login-form div {
  text-align: left;
  margin-bottom: 10px;
}

/* Style the form input fields and buttons with Bootstrap classes */
.login-form input,
.login-form button {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  margin-top: 10px;
}

/* Add styles for validation error messages */
.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 14px;
  margin-top: 4px;
}
.circle {
  width: 428px;
  height: 428px;
  border-radius: 50%;
  border: 47px solid #ededf7;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.semi-circle {
  width: 250px;
  width: 4;
  height: 250px;
  border-radius: 0 0 265px 0;
  border: 47px solid #ededf7;
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: rotate(75deg);
  transform: rotate(179deg);
  border-left: transparent;
  border-top: transparent;
}
.top-left {
  top: -200px;
  left: -9%;
}

.bottom-right {
  bottom: 2px;
  right: 2px;
}
</style>
