<template>
  <div v-if="pagination">
    <div
      class="d-flex justify-content-center gap-3 align-middle align-items-center h-auto"
    >
      <button
        type="button"
        class="btn btn-primary"
        @click="currentPageHandler(1)"
      >
        First
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        :disabled="pagination.current_page === 1 ? true : false"
        @click="currentPageHandler(pagination.current_page - 1)"
      >
        Prev
      </button>

      <input
        type="number"
        class="form-control form-control-sm text-center"
        style="width: 50px; -webkit-appearance: none; margin: 0"
        v-model="pagination.current_page"
        :min="1"
        @keyup.enter="currentPageHandler(pagination.current_page)"
      />

      <button
        type="button"
        class="btn btn-outline-primary"
        :disabled="
          pagination.current_page === pagination.last_page ? true : false
        "
        @click="currentPageHandler(pagination.current_page + 1)"
      >
        Next
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="currentPageHandler(pagination.last_page)"
      >
        Last
      </button>
    </div>
    <div class="text-center text-white">
      Total page: {{ pagination.last_page }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: [Object, null],
      required: true,
    },
    currentPageHandler: {
      type: Function,
      required: true,
    },
  },
};
</script>
