import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=6a47d40a&scoped=true&slot=header"
import script from "./Projects.vue?vue&type=script&lang=js"
export * from "./Projects.vue?vue&type=script&lang=js"
import style0 from "./Projects.vue?vue&type=style&index=0&id=6a47d40a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a47d40a",
  null
  
)

export default component.exports