<template>
  <div>
    <h2 style="color: #fff; margin-bottom: 30px">Device Type Management</h2>
    <div class="mt-3">
      <input
        type="text"
        class="form-control input-group"
        placeholder="Search..."
        v-model="searchKeyword"
        @keyup="handleTableSearch"
      />
    </div>
    <table class="table table-striped mt-3">
      <thead>
        <tr>
          <!-- <th>User Image</th> -->
          <th>Device Name</th>
          <th>Label</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="device in devices" :key="device.id">
          <td>{{ device.device_type_name }}</td>
          <td>{{ device.label }}</td>
          <td>
            <button
              class="btn btn-info btn-sm"
              @click="showModal('edit', device)"
            >
              Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination
      :pagination="pagination"
      :currentPageHandler="currentPageHandler"
    />

    <!-- Add/Edit User Modal -->
    <b-modal ref="my-modal" title="Devices">
      <div>
        <label for="name">Label:</label>
        <b-form-input v-model="label" id="name" required></b-form-input>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saveDevice">Save</b-button>
        <b-button variant="secondary" @click="closeModal">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import config from "../../../config";
import TablePagination from "../../../components/Pagination/TablePagination.vue";
export default {
  data() {
    return {
      pagination: {},
      devices: [],
      label: "",
      modalVisible: false,
      showModalType: null,
      searchKeyword: "",
    };
  },
  components: {
    TablePagination,
  },

  created() {
    this.fetchDevices();
  },
  methods: {
    closeModal() {
      this.$refs["my-modal"].hide();
    },
    async fetchDevices() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
        const response = await axios.get(config.baseURL + "device-type", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        this.devices = response.data.data;
        let paginateData = {
          current_page: response.data.current_page,
          last_page: response.data.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    async currentPageHandler(page) {
      try {
        if (page > this.pagination.last_page || page <= 0) {
          alert(
            `Invalid page number. Max page is: ${this.pagination.last_page}`
          );
          return;
        }

        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
        const response = await axios.get(
          config.baseURL + "device-type?search=" + this.searchKeyword+"&page="+page,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        this.devices = response.data.data;
        let paginateData = {
          current_page: response.data.current_page,
          last_page: response.data.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    showModal(type, data) {
      this.showModalType = type;
      if (type == "edit") {
        this.label = data.label || "";
        this.Id = data.id;
      } else {
        this.label = "";
        this.Id = "";
      }
      this.$refs["my-modal"].show();
    },
    async saveDevice() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        let response;
        if (this.showModalType === "edit") {
          let id = this.Id;
          response = await axios.post(
            config.baseURL + "device-type/update/label",
            {
              id,
              label: this.label,
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        }
        if (response.status === 202) {
          this.currentPageHandler(this.pagination.current_page);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs["my-modal"].hide();
        }
      } catch (error) {
        console.log("i am error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    async handleTableSearch() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "device-type?search=" + this.searchKeyword,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        this.devices = response.data.data;
        let paginateData = {
          current_page: response.data.data.current_page,
          last_page: response.data.data.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
  },
};
</script>

<style scoped>
.input-group {
  width: 42% !important;
  float: right !important;
}
.table {
  --bs-table-bg: transparent !important;
}
</style>
