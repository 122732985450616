<template slot="header">
  <div>
    <h2 style="color: #fff; margin-bottom: 30px">Building Management</h2>
    <button class="btn btn-primary" @click="showModal('add')">
      Add Building
    </button>
    <div class="mt-3">
      <input type="text" class="form-control input-group" v-model="searchKeyword" @keyup.enter="handleTableSearch"
        placeholder="Search..." />
    </div>
    <table class="table mt-3">
      <thead>
        <tr>
          <th>Name</th>
          <th>Project Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="building in buildings" :key="building.id">
          <td>{{ building.name }}</td>
          <td>{{ building.project.project_name }}</td>
          <td>
            <button class="btn btn-info btn-sm" @click="openFloorModal(building)">
              Floor
            </button>
            &nbsp;
            <button class="btn btn-info btn-sm" @click="showModal('edit', building)">
              Edit
            </button>
            <!-- &nbsp;
                        <button class="btn btn-danger btn-sm" @click="deleteProject(project.id)">
                            Delete
                        </button> -->
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination :pagination="pagination" :currentPageHandler="currentPageHandler" />
    <!-- Add/Edit User Modal -->
    <b-modal ref="my-modal" title="Building">
      <div>
        <label for="name">Name:</label>
        <b-form-input v-model="name" id="name" required></b-form-input>
        <label for="project">Project:</label>
        <b-form-select v-model="projectId" :options="projects" @change="projectHandler"></b-form-select>
        <label for="username">Assign Floor</label>
        <treeselect v-model="selectedFloors" :multiple="true" :options="floorOptions" />
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saveBuilding">Save</b-button>
        <b-button variant="secondary" @click="closeModal">Cancel</b-button>
      </template>
    </b-modal>
    <!-- Floor Modal -->
    <b-modal ref="floor-modal" title="Floors">
      <div>
        <Floor v-for="floor in floors" :key="floor.id" :floor="floor" :fetch="currentPageHandler"
          :page="pagination.current_page" :close="closeFloorModal" />
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="closeFloorModal">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../../config";
import TablePagination from "../../../components/Pagination/TablePagination.vue";
import Floor from './Floor';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  'name': 'Buildings',
  components: { TablePagination, Floor, Treeselect },
  data() {
    return {
      pagination: {},
      Id: "",
      projects: [],
      buildings: [],
      projectId: "",
      name: "",
      modalVisible: false,
      showModalType: null,
      searchKeyword: "",
      floors: [],
      floorOptions: [],
      selectedFloors: null,
    };
  },
  created() {
    this.fetchBuldings();
    this.fetchProjects();
  },
  methods: {
    closeModal() {
      this.projectId = "";
      this.selectedFloors = null;
      this.floorOptions = [];
      this.name = "";
      this.$refs["my-modal"].hide();
    },
    closeFloorModal() {

      this.$refs["floor-modal"].hide();
    },
    showModal(type, data) {
      this.showModalType = type;
      if (type == "edit") {
        console.log(data)
        this.name = data.name;
        this.Id = data.id;
        this.projectId = data.project_id;
        this.selectedFloors = data.floors.map((item)=>item.id);
        this.floorOptions = this.projects.find((item) => item.value === data.project_id) ? this.projects.find((item) => item.value === data.project_id).floors.filter((item => item.building_id === null || item.building_id === data.id)).map((item) => ({ id: item.id, label: item.level_name })) : [];
      } else {
        this.projectId = "";
        this.name = "";
        this.Id = "";
        this.floorOptions = [];
      }
      this.$refs["my-modal"].show();
    },
    openFloorModal(building) {
      this.floors = building.floors;
      this.$refs["floor-modal"].show();
    },
    async fetchProjects() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        const response = await axios.get(config.baseURL + "project/all", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        this.projects = response.data.data.projects.map((item) => {
          return { value: item.id, text: item.project_name, floors: item.floors }
        });
      } catch (error) {
        console.log(error);
        alert("Login failed. Please try again.");
      }
    },
    async fetchBuldings() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        const response = await axios.get(config.baseURL + "building", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        this.buildings = response.data.data;
        let paginateData = {
          current_page: response.data.current_page,
          last_page: response.data.last_page,
        };
        this.pagination = paginateData;
      } catch (error) {
        console.log(error);
        alert("Login failed. Please try again.");
      }
    },
    async currentPageHandler(page) {
      try {
        if (page > this.pagination.last_page || page <= 0) {
          alert(
            `Invalid page number. Max page is: ${this.pagination.last_page}`
          );
          return;
        }
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "building?page=" + page,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        this.buildings = response.data.data;
        let paginateData = {
          current_page: response.data.current_page,
          last_page: response.data.last_page,
        };
        this.pagination = paginateData;
      } catch (error) {
        console.log(error);
        alert("Login failed. Please try again.");
      }
    },
    projectHandler(value) {
      const filteredProject = this.projects.find((item) => item.value === value);
      if (filteredProject) {
        this.floorOptions = filteredProject.floors.filter((item => item.building_id === null)).map((item) => ({ id: item.id, label: item.level_name }));
      } else {
        this.floorOptions = [];
      }
      this.selectedFloors = null;
    },
    async saveBuilding() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        let response;
        if (this.showModalType === "add") {
          response = await axios.post(
            config.baseURL + "building",
            {
              project_id: this.projectId,
              name: this.name,
              floors: this.selectedFloors
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        } else if (this.showModalType === "edit") {
          let id = this.Id;
          response = await axios.put(
            config.baseURL + "building/" + id,
            {
              project_id: this.projectId,
              name: this.name,
              floors: this.selectedFloors
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        }
        if (response) {
          this.currentPageHandler(this.pagination.current_page);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.projectId = "";
          this.selectedFloors = null;
          this.floorOptions = [];
          this.name = "";
          this.fetchProjects();
          this.$refs["my-modal"].hide();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    async handleTableSearch() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "building?search=" + this.searchKeyword,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        this.buildings = response.data.data;
        let paginateData = {
          current_page: response.data.current_page,
          last_page: response.data.last_page,
        };
        this.pagination = paginateData;
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
  }
}
</script>
<style scoped>
/* Add custom CSS to change the background color of table rows */
.table>tbody>tr>td {
  background-color: #1e1e28;
  /* Change this to your desired background color */
}

.table>thead>tr>th {
  background-color: #38384b;
}

.input-group {
  width: 42% !important;
  float: right !important;
}

.custom-select {
  color: black !important;
}

.input-group {
  width: 42% !important;
  float: right !important;
}

.pagination-button .span {
  align-self: center;
}
</style>
