<template slot="header">
  <div>
    <h2 style="color: #fff; margin-bottom: 30px">Project Managements</h2>
    <button class="btn btn-primary" @click="showModal('add')">
      Add Project
    </button>

    <div class="mt-3">
      <input type="text" class="form-control input-group" placeholder="Search..." v-model="searchKeyword"
        @keyup.enter="handleTableSearch" />
    </div>

    <table class="table mt-3">
      <thead>
        <tr>
          <!-- <th>User Image</th> -->
          <th>Project Name</th>
          <th>Group Name</th>
          <th>Company Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="project in projects" :key="project.id">
          <td>{{ project.project_name }}</td>
          <td>{{ project.group_name }}</td>
          <td>{{ project.company_name }}</td>
          <td>
            <button class="btn btn-info btn-sm" @click="openFloorModal(project)">
              Floor
            </button>
            &nbsp;
            <button class="btn btn-info btn-sm" @click="openBuildingModal(project)">
              Building
            </button>
            &nbsp;
            <button class="btn btn-info btn-sm" @click="showModal('edit', project)">
              Edit
            </button>
            &nbsp;
            <button class="btn btn-danger btn-sm" @click="deleteProject(project.id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination :pagination="pagination" :currentPageHandler="currentPageHandler" />

    <!-- Add/Edit Modal -->
    <b-modal ref="my-modal" title="Projects">
      <div>
        <label for="name">Project Name:</label>
        <b-form-input v-model="ProjectName" id="name" required></b-form-input>
        <label for="group-name">Group Name:</label>
        <b-form-input v-model="GroupName" required id="group-name"></b-form-input>
        <label for="company-name">Company Name:</label>
        <b-form-input v-model="CompanyName" required id="company-name"></b-form-input>
        <label for="no_of_building">Number of Building</label>
        <b-form-input v-model="no_of_building" type="number" required id="no_of_building" min="0"
          @change="buildingHandler"></b-form-input>
        <div v-if="showModalType === 'edit' && currentBuildingNumber - no_of_building > 0">
          <div class="">Select which building do you want to remove?</div>
          <div class="d-flex flex-wrap gap-2">
            <div v-for="building in currentBuildings" :key="building.id" class="d-flex gap-2">
              <div><input type="checkbox" :value="building.id" v-model="removeBuildings"
                  :disabled="!shouldEnableBuildingCheckbox(building.id)"></div>
              <div>{{ building.name }}</div>
            </div>
          </div>
        </div>
        <label for="no_of_level">Number of Level:</label>
        <b-form-input v-model="no_of_level" type="number" required id="no_of_level" min="0"
          @change="floorHandler"></b-form-input>
        <div v-if="showModalType === 'edit' && currentFloorNumber - no_of_level > 0">
          <div class="">Select which floor do you want to remove?</div>
          <div class="d-flex flex-wrap gap-2">
            <div v-for="floor in currentFloors" :key="floor.id" class="d-flex gap-2">
              <div><input type="checkbox" :value="floor.id" v-model="removeFloors"
                  :disabled="!shouldEnableFloorCheckbox(floor.id)"></div>
              <div>{{ floor.level_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" :disabled="!proccedToNextHandler" @click="saveProject">Save</b-button>
        <b-button variant="secondary" @click="closeModal">Cancel</b-button>
      </template>
    </b-modal>
    <!-- Floor Modal -->
    <b-modal ref="floor-modal" title="Floors">
      <div>
        <Floor v-for="floor in floors" :key="floor.id" :floor="floor" :fetch="currentPageHandler"
          :page="pagination.current_page" />
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="closeFloorModal">Close</b-button>
      </template>
    </b-modal>
    <!-- Bulding Modal -->
    <b-modal ref="building-modal" title="Buldings">
      <div>
        <Building v-for="building in buildings" :key="building.id" :building="building" :fetch="currentPageHandler"
          :page="pagination.current_page" />
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="closeBuildingModal">Close</b-button>
      </template>
    </b-modal>
  </div>

</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../../config";
import Floor from '@/pages/Admin/Projects/Floor'
import Building from '@/pages/Admin/Projects/Building'
import TablePagination from "../../../components/Pagination/TablePagination.vue";
export default {
  components: { TablePagination, Floor, Building },
  data() {
    return {
      pagination: {},
      Id: "",
      projects: [],
      CompanyName: "",
      ProjectName: "",
      GroupName: "",
      no_of_level: 0,
      no_of_building: 0,
      modalVisible: false,
      showModalType: null,
      searchKeyword: "",
      floors: [],
      buildings: [],
      currentFloorNumber: 0,
      currentBuildingNumber: 0,
      currentFloors: [],
      currentBuildings: [],
      removeFloors: [],
      removeBuildings: [],
    };
  },
  computed: {
    proccedToNextHandler() {
      if (this.showModalType === 'edit') {
        const buildingConditionMet = this.currentBuildingNumber - this.no_of_building <= 0 || this.removeBuildings.length === this.currentBuildingNumber - this.no_of_building;
        const floorConditionMet = this.currentFloorNumber - this.no_of_level <= 0 || this.removeFloors.length === this.currentFloorNumber - this.no_of_level;
        return buildingConditionMet && floorConditionMet;
      } else {
        return true;
      }
    }
  },
  watch: {
    proccedToNextHandler(newVal) {
      this.proccedToNext = newVal;
    }
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    closeModal() {
      this.$refs["my-modal"].hide();
    },
    closeFloorModal() {
      this.$refs["floor-modal"].hide();
    },
    closeBuildingModal() {
      this.$refs["building-modal"].hide();
    },
    async fetchProjects() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(config.baseURL + "project/list", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        this.projects = response.data.data.projects.data;
        let paginateData = {
          current_page: response.data.data.projects.current_page,
          last_page: response.data.data.projects.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    async currentPageHandler(page) {
      try {
        if (page > this.pagination.last_page || page <= 0) {
          alert(
            `Invalid page number. Max page is: ${this.pagination.last_page}`
          );
          return;
        }
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "project/list?page=" + page,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        this.projects = response.data.data.projects.data;
        let paginateData = {
          current_page: response.data.data.projects.current_page,
          last_page: response.data.data.projects.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    buildingHandler(value) {
      this.removeBuildings = [];
    },
    floorHandler(value) {
      this.removeFloors = [];
    },
    shouldEnableBuildingCheckbox(id) {
      return this.removeBuildings.length < this.currentBuildingNumber - this.no_of_building || this.removeBuildings.includes(id);
    },
    shouldEnableFloorCheckbox(id) {
      return this.removeFloors.length < this.currentFloorNumber - this.no_of_level || this.removeFloors.includes(id);
    },
    showModal(type, data) {
      this.showModalType = type;
      if (type == "edit") {
        this.GroupName = data.group_name;
        this.CompanyName = data.company_name;
        this.ProjectName = data.project_name;
        this.Id = data.id;
        this.no_of_level = data.no_of_level;
        this.no_of_building = data.no_of_building;
        this.currentFloorNumber = data.no_of_level;
        this.currentBuildingNumber = data.no_of_building;
        this.currentFloors = data.floors;
        this.currentBuildings = data.buildings;
      } else {
        this.GroupName = "";
        this.CompanyName = "";
        this.ProjectName = "";
        this.Id = "";
        this.no_of_level = 0;
        this.no_of_building = 0;
      }
      this.$refs["my-modal"].show();
    },
    openFloorModal(project) {
      this.floors = project.floors;
      this.$refs["floor-modal"].show();
    },
    openBuildingModal(project) {
      this.buildings = project.buildings;
      this.$refs["building-modal"].show();
    },
    deleteProject(Id) {
      const index = this.projects.findIndex((project) => project.id === Id);

      const token = localStorage.getItem("token");
      const token_type = localStorage.getItem("token_type");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(
              config.baseURL + "project/delete/" + Id,
              {},
              {
                headers: {
                  Authorization: `${token_type} ${token}`,
                },
              }
            );
            if (response.status === 200) {
              if (index !== -1) {
                this.projects.splice(index, 1);
              }
              Swal.fire("Deleted!", "Your data has been deleted.", "success");
            } else {
              Swal.fire(
                "Error!",
                "An error occurred while deleting the project.",
                "error"
              );
            }
          } catch (error) {
            Swal.fire(
              "Error!",
              "An error occurred while deleting the project.",
              "error"
            );
          }
        }
      });
    },

    async saveProject() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        let response;

        if (this.showModalType === "add") {
          response = await axios.post(
            config.baseURL + "project/create",
            {
              project_name: this.ProjectName,
              group_name: this.GroupName,
              company_name: this.CompanyName,
              no_of_level: this.no_of_level,
              no_of_building: this.no_of_building,
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        } else if (this.showModalType === "edit") {
          let id = this.Id;
          response = await axios.post(
            config.baseURL + "project/update/" + id,
            {
              project_name: this.ProjectName,
              group_name: this.GroupName,
              company_name: this.CompanyName,
              no_of_level: this.no_of_level,
              no_of_building: this.no_of_building,
              removeFloors: this.removeFloors,
              removeFloors: this.removeFloors,
              removeBuildings: this.removeBuildings,
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        }
        if (response) {
          this.currentPageHandler(this.pagination.current_page);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs["my-modal"].hide();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    async handleTableSearch() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "project/list?search=" + this.searchKeyword,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        console.log(response.data.data.projects);
        this.projects = response.data.data.projects.data;
        let paginateData = {
          current_page: response.data.data.projects.current_page,
          last_page: response.data.data.projects.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
  },
};
</script>
<style scoped>
/* Add custom CSS to change the background color of table rows */
.table>tbody>tr>td {
  background-color: #1e1e28;
  /* Change this to your desired background color */
}

.table>thead>tr>th {
  background-color: #38384b;
}

.input-group {
  width: 42% !important;
  float: right !important;
}

.custom-select {
  color: black !important;
}

.input-group {
  width: 42% !important;
  float: right !important;
}

.pagination-button .span {
  align-self: center;
}
</style>
