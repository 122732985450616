<template>
  <div class="d-flex align-items-center gap-2">
    <b-form-input :value="building.name" @input="updateLevelName" id="name" :required="isRequired" @keydown="makeEditable"></b-form-input>
    <b-button :variant="editable ? 'primary' : 'secondary'" :disabled="!editable" @click="updateAction">
      <div class="spinner-border spinner-border-sm" role="status" v-if="loading">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-else>
        Save
      </div>
    </b-button>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";
import config from '../../../config';
export default {
  name: "Building",
  data() {
    return {
      editable: false,
      name:this.building.name,
      loading:false,
    };
  },
  props: {
    building: {
      type: Object,
      required: true,
    },
    page: {
      type: [String,Number],
      required: true,
    },
    fetch: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isRequired() {
      // Add any condition to determine if the input should be required
      return true; // Replace with your condition
    },
  },
  methods: {
    makeEditable() {
      // Set editable to true on any key press
      this.editable = true;
    },
    updateLevelName(newValue) {
      this.name = newValue;
    },
    async updateAction(){
      this.loading = true;
      const token = localStorage.getItem("token");
      const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
      const response = await axios.put(config.baseURL + "project/building", {id:this.building.id,name:this.name},{
        headers: {
          Authorization: `${token_type} ${token}`,
        },
      });
      if (response.status === 202) {
        this.editable = false;
        this.fetch(this.page);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.loading = false;
    }
  },
};
</script>
