<template>
  <div>
    <h2 style="color: #fff; margin-bottom: 30px">User Management</h2>
    <button class="btn btn-primary" id="show-btn" @click="showModal('add')">
      Add User
    </button>
    <div class="mt-3">
      <div class="input-group">
        <input v-model="searchKeyword" class="form-control" type="text" placeholder="Search..."
          @keyup.enter="handleTableSearch" />
      </div>
    </div>

    <table class="table table-striped mt-3">
      <thead>
        <tr>
          <th>Full Name</th>
          <th>User Name</th>
          <th>Type</th>
          <th>Project</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.full_name }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.type }}</td>
          <td>{{ user.project?.project_name }}</td>
          <td>
            <button v-if="user.type.toLowerCase() === 'useradmin'" class="btn btn-info btn-sm"
              @click="userDeviceMqtt(user.id)">
              MQTT
            </button>
            &nbsp;
            <button class="btn btn-info btn-sm" @click="showModal('edit', user)">
              Edit
            </button>
            &nbsp;
            <button class="btn btn-danger btn-sm" @click="deleteUser(user.id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <TablePagination :pagination="pagination" :currentPageHandler="currentPageHandler" />

    <!-- Add your modal here -->
    <b-modal ref="my-modal" title="Users">
      <div>
        <label for="name">Full Name:</label>
        <b-form-input v-model="editedUser.full_name" id="name" required></b-form-input>
        <label for="username">User Name:</label>
        <b-form-input v-model="editedUser.username" required id="username"></b-form-input>
        <label for="username">Type:</label>
        <v-select v-model="editedUser.type" :options="TypeOptions" label="text" value="id"></v-select>
        <label for="dynamicSelect">Select a Project:</label>
        <treeselect v-model="permissions" :multiple="true" :options="projects" @input="handleSelectionChange" />
        <label v-if="!isEdit" for="password">Password:</label>
        <div v-if="!isEdit" class="password-input">
          <input class="form-control" id="password" v-model="password" :type="showPassword ? 'text' : 'password'" />
          <button @click="togglePasswordVisibility">
            <i :class="[
      'far',
      showPassword
        ? 'bi bi-eye-slash-fill'
        : 'bi bi-eye',
    ]"></i>
          </button>
        </div>
        <button class="btn btn-info" style="color: #fff" v-if="!isEdit" @click="generatePassword">
          Generate Password
        </button>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saveUser">Save</b-button>
        <b-button variant="secondary" @click="closeModal">Cancel</b-button>
      </template>
    </b-modal>
    <!-- Add your modal here -->
    <b-modal ref="my-mqtt" title="MQTT">
      <div>
        <b-form-group label="Device Types">
          <b-form-select v-if="deviceTypeList" :options="deviceTypeList" v-model="selectedDeviceType" multiple
            required></b-form-select>
        </b-form-group>
        <b-form-group label="Status">
          <b-form-select v-if="deviceTypeList" v-model="selectedMqtt" :options="mqttStatus" required></b-form-select>
        </b-form-group>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="submitDeviceMqtt">Save</b-button>
        <b-button variant="secondary" @click="closeUserDeviceMqtt">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import config from "../../../config";
import TablePagination from "../../../components/Pagination/TablePagination.vue";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  components: {
    vSelect,
    TablePagination,
    Treeselect
  },
  data() {
    return {
      permissions: null,
      pagination: {},
      password: "", // Input field for the password
      showPassword: false, // Flag to toggle password visibility
      generatedPassword: "", // Display generated password here
      users: [],
      projects: [],
      selectedType: null,
      showModalType: "",
      TypeOptions: [
        { value: "Admin", text: "Super Admin" },
        { value: "User", text: "User" },
        { value: "Useradmin", text: "User Admin" },
      ],
      isEdit: false,

      editedUser: {
        id: null,
        full_name: "",
        username: "",
        type: "",
        projects: "",
      },
      searchKeyword: "",
      deviceTypeList: null,
      selectedDeviceType: [],
      mqttStatus: [
        { value: 0, text: 'Disable' },
        { value: 1, text: 'Enable' },
      ],
      selectedMqtt: null,
    };
  },
  created() {
    this.fetchUsers();
    this.fetchProjects();
  },
  methods: {
    handleSelectionChange(newPermissions) {
      const newPermissionsSet = new Set(newPermissions);
      const processedPermissions = new Set();
      let selectedParent = null;

      this.projects.forEach(project => {
        if (project.children) {
          const hasSelectedChild = project.children.some(child => newPermissionsSet.has(child.id));
          const isParentSelected = newPermissionsSet.has(project.id);

          if (isParentSelected) {
            if (selectedParent) {
              // If another parent is already selected, ignore this parent
              return;
            } else {
              // Select this parent and ignore its children
              selectedParent = project.id;
              processedPermissions.clear(); // Clear any selected children from other parents
              processedPermissions.add(project.id);
            }
          } else if (hasSelectedChild) {
            if (selectedParent && selectedParent !== project.id) {
              // If children from another parent are selected, ignore these children
              return;
            } else {
              // Select these children
              selectedParent = project.id;
              project.children.forEach(child => {
                if (newPermissionsSet.has(child.id)) {
                  processedPermissions.add(child.id);
                }
              });
            }
          }
        } else if (newPermissionsSet.has(project.id)) {
          if (selectedParent) {
            // If another parent is already selected, ignore this parent
            return;
          } else {
            // Select this parent
            selectedParent = project.id;
            processedPermissions.add(project.id);
          }
        }
      });

      // Update permissions with processed permissions
      this.permissions = Array.from(processedPermissions);
    },
    togglePasswordVisibility() {
      // Toggle password visibility
      this.showPassword = !this.showPassword;
    },
    generatePassword() {
      // Generate a random password (you can customize this logic)
      const length = 10; // Password length
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let newPassword = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        newPassword += charset[randomIndex];
      }
      this.generatedPassword = newPassword;
      this.password = newPassword;
    },

    closeModal() {
      this.$refs["my-modal"].hide();
    },
    async fetchUsers() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
        const response = await axios.get(config.baseURL + "user/list", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        this.users = response.data.data.users.data;
        let paginateData = {
          current_page: response.data.data.users.current_page,
          last_page: response.data.data.users.last_page,
        };
        this.pagination = paginateData;
      } catch (error) {
        alert("Login failed. Please try again.");
      }
    },
    async fetchProjects() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "project/all",
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        //console.log(response.data.data.projets);
        // this.projects = response.data.data.projects;
        this.projects = response.data.data.projects.map((project) => {
          const { id, project_name, buildings } = project;
          let children = [];
          if (buildings.length > 0) {
            children = buildings.map((building) => {
              const { id, name } = building;
              return { id: `building-${id}`, label: name };
            });
          }
          if (children.length > 0) {
            return { id: `project-${id}`, label: project_name, children };
          }
          return { id: `project-${id}`, label: project_name };
        });
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    showModal(type, data) {
      this.showModalType = type;
      if (type == "edit") {
        this.editedUser.full_name = data.full_name;
        this.editedUser.username = data.username;
        this.editedUser.type = data.type;
        this.permissions = data.permissions;
        this.editedUser.id = data.id;
        this.isEdit = true;
      } else {
        this.editedUser.full_name = "";
        this.editedUser.username = "";
        this.editedUser.type = "";
        this.permissions = null;
        this.Id = "";
        this.isEdit = false;
      }
      this.$refs["my-modal"].show();
    },
    async userDeviceMqtt(id) {
      const token = localStorage.getItem("token");
      const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
      const response = await axios.get(
        config.baseURL + "user/devices/mqtt?user=" + id,
        {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        }
      );
      if (response.status === 200) {
        this.deviceTypeList = response.data.data.devices.map((device) => {
          const { mqtt } = device;
          const { id, device_type_name: name } = device.device_type;
          if (mqtt) {
            this.selectedDeviceType.push(id);
          }
          return { value: id, text: name, mqtt }
        });
        const mqttEnable = this.deviceTypeList.find((item) => item.mqtt);
        if (mqttEnable) {
          this.selectedMqtt = 1;
        }
      }
      this.$refs["my-mqtt"].show();
    },
    closeUserDeviceMqtt() {
      this.selectedMqtt = null;
      this.selectedDeviceType = [];
      this.$refs["my-mqtt"].hide();
    },
    async submitDeviceMqtt() {
      if (this.selectedDeviceType.length <= 0) {
        Swal.fire({
          icon: "error",
          title: "Select Device Types",
          text: "You must need to select at least one device type",
        });
      } else if (this.selectedMqtt === null) {
        Swal.fire({
          icon: "error",
          title: "Select MQTT Status",
          text: "You must need to select mqtt status",
        });
      } else {
        const data = {
          mqtt: this.selectedMqtt,
          device_types: this.selectedDeviceType,
        };
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
        const response = await axios.post(
          config.baseURL + "user/devices/mqtt/update", data,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "MQTT Status",
            text: "MQTT status has been changed successfully",
          });
          this.closeUserDeviceMqtt();
        } else {
          Swal.fire({
            icon: "error",
            title: "MQTT Status",
            text: "Server side error! Please try again later",
          });
        }
      }
    },

    deleteUser(userId) {
      const index = this.users.findIndex((user) => user.id === userId);
      const token = localStorage.getItem("token");
      const token_type = localStorage.getItem("token_type");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(
              config.baseURL + "user/delete/" + userId,
              {},
              {
                headers: {
                  Authorization: `${token_type} ${token}`,
                },
              }
            );
            if (response.status === 200) {
              if (index !== -1) {
                this.users.splice(index, 1);
              }
              Swal.fire(
                "Deleted!",
                "Your data has been deleted.",
                "success"
              );
            } else {
              Swal.fire(
                "Error!",
                "An error occurred while deleting the user.",
                "error"
              );
            }
          } catch (error) {
            Swal.fire(
              "Error!",
              "An error occurred while deleting the user.",
              "error"
            );
          }
        }
      });
    },
    async currentPageHandler(page) {
      try {
        if (page > this.pagination.last_page || page <= 0) {
          alert(
            `Invalid page number. Max page is: ${this.pagination.last_page}`
          );
          return;
        }
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
        const response = await axios.get(
          config.baseURL + "user/list?page=" + page,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        this.users = response.data.data.users.data;
        let paginateData = {
          current_page: response.data.data.users.current_page,
          last_page: response.data.data.users.last_page,
        };
        this.pagination = paginateData;
      } catch (error) {
        alert("Login failed. Please try again.");
      }
    },
    async saveUser() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");

        let response;
        if (this.showModalType === "add") {
          response = await axios.post(
            config.baseURL + "user/create",
            {
              full_name: this.editedUser.full_name,
              username: this.editedUser.username,
              type: this.editedUser.type.value,
              permissions: this.permissions,
              password: this.password,
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        } else if (this.showModalType === "edit") {
          let id = this.editedUser.id;
          /*
           * this block is added for get user type and project id
           */
          let userType = "";
          console.log(this.editedUser.type)
          if (typeof this.editedUser.type === "string") {
            userType = this.TypeOptions.find(
              (item) => item.text === this.editedUser.type || item.value === this.editedUser.type
            ).value;
          } else {
            userType = this.editedUser.type.value;
          }
          /*
           * block end
           */
          response = await axios.post(
            config.baseURL + "user/update/" + id,
            {
              full_name: this.editedUser.full_name,
              username: this.editedUser.username,
              type: userType,
              permissions: this.permissions,
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        }

        if (response) {
          this.currentPageHandler(this.pagination.current_page);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs["my-modal"].hide();
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },

    async handleTableSearch() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "user/list?search=" + this.searchKeyword,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        console.log(response.data.data.users);
        this.users = response.data.data.users.data;
        let paginateData = {
          current_page: response.data.data.users.current_page,
          last_page: response.data.data.users.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
  },
};
</script>

<style scoped>
/* Add custom CSS to change the background color of table rows */
.table>tbody>tr>td {
  background-color: #1e1e28;
  /* Change this to your desired background color */
}

.table>thead>tr>th {
  background-color: #38384b;
}

.input-group {
  width: 42% !important;
  float: right !important;
}

.custom-select {
  color: black !important;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 40px;
  /* Space for the eye icon button */
}

.password-input button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.generated-password {
  margin-top: 10px;
}
</style>
