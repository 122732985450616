<template>
 <div class="loader-container">
  <div class="loader"></div>
</div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; 
}

.loader {
  width: 48px;
  height: 48px;
  background: #4a4e94;
  position: relative;
  box-sizing: border-box;
  animation: rotationBack 2s ease-in-out infinite reverse;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  left: 0;
  top: 0;
  transform: rotate(45deg);
  position: absolute;
  width: 48px;
  height: 48px;
  background: #4a4e94;
  box-shadow: 0 0 5px #4a4e94;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background:#22664a;
  box-shadow: 0 0 5px #ededf7;
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>