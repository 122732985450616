<template>
  <div>
    <h2 style="color: #fff; margin-bottom: 30px">Device Management</h2>
    <button class="btn btn-primary" @click="showModal('add')">
      Add New Device
    </button>
    <div class="mt-3">
      <input
        type="text"
        class="form-control input-group"
        placeholder="Search..."
        v-model="searchKeyword"
        @keyup="handleTableSearch"
      />
    </div>
    <table class="table table-striped mt-3">
      <thead>
        <tr>
          <!-- <th>User Image</th> -->
          <th>Device Name</th>
          <th>Serial Number</th>
          <th>Device Type</th>
          <th>Project Name</th>
          <th>MQTT</th>
          <th>IsActive</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="device in devices" :key="device.id">
          <!-- <td>{{ user.user_image }}</td> -->
          <td>{{ device.device_name }}</td>
          <td>{{ device.serial_number }}</td>
          <td>{{ device.device_type?.device_type_name }}</td>
          <td>{{ device.project?.project_name }}</td>
          <td>{{ device.mqtt == 1 ? 'Enable' : 'Disable' }}</td>
          <td>{{ device.active == 1 ? 'Active' : 'Inactive' }}</td>
          <td>
            <button
              class="btn btn-info btn-sm"
              @click="showModal('edit', device)"
            >
              Edit
            </button>
            &nbsp;
            <button class="btn btn-danger btn-sm" @click="deleteDevice(device.id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination
      :pagination="pagination"
      :currentPageHandler="currentPageHandler"
    />

    <!-- Add/Edit User Modal -->
    <b-modal ref="my-modal" title="Devices">
      <div>
        <label for="name">Device Name:</label>
        <b-form-input v-model="deviceName" id="name" required></b-form-input>
        <label for="username">Serial Number:</label>
        <b-form-input
          v-model="serialNumber"
          required
          id="username"
        ></b-form-input>
        <label for="username">Type:</label>
        <v-select
          v-model="deviceType"
          :options="TypeOptions"
          label="device_type_name"
          value="id"
        ></v-select>

        <label for="dynamicSelect">Select a Project:</label>
        <v-select
          v-model="deviceProject"
          :options="projects"
          label="project_name"
          value="id"
        ></v-select>

        <label for="dynamicSelect">Is Active:</label>
        <v-select
          v-model="activeInactive"
          :options="activeOrInactive"
          label="text"
          value="value"
        ></v-select>
        <label for="dynamicSelect">MQTT Status</label>
        <v-select
          v-model="mqtt"
          :options="mqttOption"
          label="text"
          value="value"
        ></v-select>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saveDevice">Save</b-button>
        <b-button variant="secondary" @click="closeModal">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import config from "../../../config";
import TablePagination from "../../../components/Pagination/TablePagination.vue";
export default {
  data() {
    return {
      pagination: {},
      devices: [],
      projects: [],
      TypeOptions: [],
      activeInactive: { value: "0", text: "Inactive" },
      activeOrInactive: [
        { value: "0", text: "Inactive" },
        { value: "1", text: "Active" },
      ],
      mqtt: { value: "0", text: "Disable" },
      mqttOption: [
        { value: "0", text: "Disable" },
        { value: "1", text: "Enable" },
      ],
      deviceProject: "",
      deviceType: "",
      deviceName: "",
      serialNumber: "",

      modalVisible: false,
      showModalType: null,
      searchKeyword: "",
    };
  },
  components: {
    vSelect,
    TablePagination,
  },

  created() {
    this.fetchDevices();
    this.fetchProjects();
    this.fetchDeviceTypes();
  },
  methods: {
    closeModal() {
      this.$refs["my-modal"].hide();
    },
    async fetchDevices() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
        const response = await axios.get(config.baseURL + "device/list", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        this.devices = response.data.data.devices.data;
        let paginateData = {
          current_page: response.data.data.devices.current_page,
          last_page: response.data.data.devices.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    async currentPageHandler(page) {
      try {
        if (page > this.pagination.last_page || page <= 0) {
          alert(
            `Invalid page number. Max page is: ${this.pagination.last_page}`
          );
          return;
        }

        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token
        const response = await axios.get(
          config.baseURL + "device/list?search=" + this.searchKeyword+"&page="+page,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        console.log(response.data.data);
        this.devices = response.data.data.devices.data;
        let paginateData = {
          current_page: response.data.data.devices.current_page,
          last_page: response.data.data.devices.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    showModal(type, data) {
      this.showModalType = type;
      if (type == "edit") {
        this.deviceName = data.device_name;
        this.serialNumber = data.serial_number;
        this.deviceType = data.device_type?.device_type_name;
        this.Id = data.id;
        this.deviceProject = data.project?.project_name;
        this.activeInactive = data.active == 1 ? "Active" : "Disable";
        this.mqtt = data.mqtt === 1 ? "Enable" : "Disable";
      } else {
        this.deviceName = "";
        this.serialNumber = "";
        this.deviceType = "";
        this.Id = "";
        this.deviceProject = "";
      }
      this.$refs["my-modal"].show();
    },
    async saveDevice() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        let response;
        if (this.showModalType === "add") {
          response = await axios.post(
            config.baseURL + "device/create",
            {
              device_name: this.deviceName,
              serial_number: this.serialNumber,
              device_type_id: this.deviceType.id,
              project_id: this.deviceProject.id,
              active: this.activeInactive.value,
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        } else if (this.showModalType === "edit") {
          console.log(this.showModalType);
          /*
           * this bolck is added to get deviceType id and project id properly
           */
          let deviceTypeId = "";
          if (typeof this.deviceType === "string") {
            deviceTypeId = this.TypeOptions.find(
              (item) => item.device_type_name === this.deviceType
            ).id;
          } else {
            deviceTypeId = this.deviceType.id;
          }
          let project = "";
          if (typeof this.deviceProject === "string") {
            project = this.projects.find(
              (item) => item.project_name === this.deviceProject
            ).id;
          } else {
            project = this.deviceProject.id;
          }
          let status = "";

          if (typeof this.activeInactive === "string") {
            status = this.activeOrInactive.find(
              (item) => item.text === this.activeInactive
            ).value;
          } else {
            status = this.activeInactive.value;
          }
          /*
           * block end
           */
          let mqttStatus = "";
          if (typeof this.mqtt === "string") {
            if (this.mqtt.toLowerCase() === 'enable') {
              mqttStatus = 1;
            } else {
              mqttStatus = 0;
            }
          } else {
            mqttStatus = this.mqtt.value;
          }
          let id = this.Id;
          response = await axios.post(
            config.baseURL + "device/update/" + id,
            {
              device_name: this.deviceName,
              serial_number: this.serialNumber,
              device_type_id: deviceTypeId,
              project_id: project,
              active: status,
              mqtt: mqttStatus,
            },
            {
              headers: {
                Authorization: `${token_type} ${token}`,
              },
            }
          );
        }
        console.log(response);
        if (response) {
          this.currentPageHandler(this.pagination.current_page);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs["my-modal"].hide();
        }
      } catch (error) {
        console.log("i am error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    deleteDevice(Id) {
      const index = this.devices.findIndex((device) => device.id === Id);
      const token = localStorage.getItem("token");
      const token_type = localStorage.getItem("token_type");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(
              config.baseURL + "device/delete/" + Id,
              {},
              {
                headers: {
                  Authorization: `${token_type} ${token}`,
                },
              }
            );
            if (response.status === 200) {
              if (index !== -1) {
                this.devices.splice(index, 1);
              }
              Swal.fire("Deleted!", "Your data has been deleted.", "success");
            } else {
              Swal.fire(
                "Error!",
                "An error occurred while deleting the device.",
                "error"
              );
            }
          } catch (error) {
            Swal.fire(
              "Error!",
              "An error occurred while deleting the device.",
              "error"
            );
          }
        }
      });
    },
    async fetchProjects() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(config.baseURL + "project/all", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        this.projects = response.data.data.projects;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
    async fetchDeviceTypes() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(config.baseURL + "device-type/list", {
          headers: {
            Authorization: `${token_type} ${token}`,
          },
        });
        //console.log(response.data.data);
        this.TypeOptions = response.data.data.device_types;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
    async handleTableSearch() {
      try {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type"); // Replace with your actual access token

        // console.log("token type", token);
        const response = await axios.get(
          config.baseURL + "device/list?search=" + this.searchKeyword,
          {
            headers: {
              Authorization: `${token_type} ${token}`,
            },
          }
        );
        console.log(response.data.data.devices);
        this.devices = response.data.data.devices.data;
        let paginateData = {
          current_page: response.data.data.devices.current_page,
          last_page: response.data.data.devices.last_page,
        };
        this.pagination = paginateData;
        // this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
  },
};
</script>

<style scoped>
.input-group {
  width: 42% !important;
  float: right !important;
}
.table {
  --bs-table-bg: transparent !important;
}
</style>
